import Button from "./Button";
import styles from "./ContactBlock.module.css";

const ContactBlock = (props: {
  title?: string;
  cta_text?: string;
  cta_link?: string;
  eventName: string;
  moduleComponent: string;
  elementName: string;
  cta_type: string;
  children?: React.ReactNode;
  buttonLeft?: boolean;
  cupraLinkBlock?: boolean;
  narrow?: boolean;
  narrow50?: boolean;
  smallText?: boolean;
}) => {
  return (
    <div
      className={`${styles.ContactBlockContainer} ${
        props.narrow ? styles.ContactBlockNarrow : ""
      } ${props.cupraLinkBlock ? styles.CupraLinkBlock : ""} ${
        props.narrow50 ? styles.HalfBlock : ""
      }`}
    >
      {props.title && (
        <div>
          <h1 className={styles.ContactBlockTitle}>{props.title}</h1>
        </div>
      )}
      <div className={styles.ContactBlockTitleContent}>{props.children}</div>
      {props.cta_link && props.cta_text && (
        <div className={styles.ContactBlockButton}>
          {props.cupraLinkBlock ? (
            props.smallText ? (
              <Button
                cta_link={props.cta_link}
                cta_text={props.cta_text}
                eventName={props.eventName}
                moduleComponent={props.moduleComponent}
                elementName={props.elementName}
                cta_type={props.cta_type}
                contactButton
                // smallText
              ></Button>
            ) : (
              <Button
                cta_link={props.cta_link}
                cta_text={props.cta_text}
                eventName={props.eventName}
                moduleComponent={props.moduleComponent}
                elementName={props.elementName}
                cta_type={props.cta_type}
                contactButton
              ></Button>
            )
          ) : (
            <Button
              cta_link={props.cta_link}
              cta_text={props.cta_text}
              eventName={props.eventName}
                moduleComponent={props.moduleComponent}
                elementName={props.elementName}
                cta_type={props.cta_type}
              left
              transparent
            ></Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactBlock;

import React from "react";
import Heading from "./comp/Heading";
import ContactBlock from "./comp/ContactBlock";
import TelefonLogo from "../assets/CUPRA_TELEFONISCHER-KONTAKT.png";

import styles from "./CustomerService.module.css";
import useWindowDimensions from "./useWindowDimensions";

const CustomerService = () => {
  const { width } = useWindowDimensions();

  return (
    <div className={`Container ${styles.ContainerBottom}`}>
      <div className={styles.HeaderWrapper} style={{ paddingBottom: "50px" }}>
        <h1 className={styles.Eyebrow}>CUPRA Customer Service</h1>
        <Heading title="Für allgemeine Anfragen." />
      </div>
      <div className={styles.ContactBlocks}>
        <ContactBlock
          title="Kontaktformular"
          cta_text="Anfrage stellen"
          cta_link="https://www.cupraofficial.de/kontakt/kdformular"
          eventName="internalLink"
          moduleComponent="Kontaktformular"
          elementName="Für allgemeine Anfragen."
          cta_type="Primary CTA"
          buttonLeft
          narrow50
        >
          {width <= 768 ? (
            <p style={{ marginTop: "0px", marginBottom: "0px", width: "101%" }}>
              Für jede Art von Fragen. Anliegen.
              <br />
              Oder Feedback. Wir melden <br />
              uns schnellstmöglich bei dir.
            </p>
          ) : (
            <p style={{ fontSize: 16, width: "85%" }}>
              Für jede Art von Fragen. Anliegen. <br />
              Oder Feedback. Wir melden uns schnellstmöglich bei dir.
            </p>
          )}
        </ContactBlock>

        <ContactBlock
          title="Whatsapp-Chat"
          cta_text="Chat starten"
          cta_link="https://wa.me/message/ZQCMH7HMQLNHI1"
          eventName="externalLink"
          moduleComponent="Whatsapp Chat"
          elementName="Für allgemeine Anfragen."
          cta_type="Primary CTA"
          buttonLeft
          narrow50
        >
          {width <= 768 ? (
            <p style={{ marginTop: "0px", marginBottom: "0px" }}>
              Montag bis Freitag zwischen 8 und 19 Uhr sowie Samstag zwischen 9
              und 16:30 Uhr chatten unsere Expertinnen und Experten mit dir.
              Außerhalb der Servicezeiten erhältst du von unserem Digital
              Assistant Hilfe.{" "}
              <a
                href="https://www.cupraofficial.de/kontakt-whatsapp"
                target="_top"
                className={styles.blackLink}
              >
                Mehr Infos zu unserem WhatsApp-Chat
              </a>
              .
            </p>
          ) : (
            <p style={{ fontSize: 16, width: "105%" }}>
              Montag bis Freitag zwischen 8 und 19 Uhr sowie Samstag zwischen 9
              und 16:30 Uhr chatten unsere Expertinnen und Experten mit dir.
              Außerhalb der Servicezeiten erhältst du von unserem Digital
              Assistant Hilfe.{" "}
              <a
                href="https://www.cupraofficial.de/kontakt-whatsapp"
                target="_top"
                className={styles.blackLink}
              >
                Mehr Infos zu unserem WhatsApp-Chat
              </a>
              .
            </p>
          )}
          {/* <p className={styles.AdditionalLink}></p> */}
        </ContactBlock>

        {width <= 768 && (
          <ContactBlock
            title="Telefonischer Kontakt"
            cta_text="Jetzt anrufen"
            cta_link="tel:004961501079991"
            eventName="interaction"
            moduleComponent="Telefonischer Kontakt"
            elementName="Für allgemeine Anfragen."
            cta_type="Primary CTA"
          >
            <p style={{ marginTop: "0px", marginBottom: "0px" }}>
              Persönlich. Und individuell. <br />
              Von Montag bis Freitag 8:00 bis 20:00 Uhr.
              <br /> Samstag 9:00 bis 17:30 Uhr.
            </p>
          </ContactBlock>
        )}
      </div>
      {width > 768 && (
        <div className={styles.PhoneContact}>
          <div className={styles.PhoneHeaderContainer}>
            <h1 className={styles.PhoneHeaderTitle}>Telefonischer Kontakt</h1>
            <div className={styles.PhoneHeaderTitleContent}>
              <p>Persönlich. Und individuell. </p>
              <p>
                Von Montag bis Freitag 8:00 bis 20:00 Uhr.
                <br /> Samstag 9:00 bis 17:30 Uhr.
              </p>
            </div>
          </div>
          <div className={styles.PhoneData}>
            <div>
              <img src={TelefonLogo} alt="Telefon" height="50" />
            </div>
            <div className={styles.PhoneNumber}>+49 6150 – 107 99 91</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerService;

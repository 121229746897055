import React from "react";
import styles from "./CupraLinks.module.css";
import ContactBlock from "./comp/ContactBlock";
import useWindowDimensions from "./useWindowDimensions";

const CupraLinks = () => {
  const { width } = useWindowDimensions();
  return (
    <div className="Container">
      <div className={styles.CupraLinksContainer}>
        <div className={styles.CupraLinksContactBlocks}>
          <ContactBlock
            cta_link="https://www.cupraofficial.de/services/stock-locator"
            cta_text="CUPRA Fahrzeugsuche"
            eventName="internalLink"
            moduleComponent="CUPRA Fahrzeugsuche"
            elementName="Cupra Links"
            cta_type="Primary CTA"
            cupraLinkBlock
            narrow
            smallText
          >
            <div className={styles.CupraLinkParagraph}>
              <p>Don't wait.</p>
              <p>Diese Fahrzeuge sind sofort verfügbar.</p>
            </div>
          </ContactBlock>
          <div className={styles.Divider}></div>
          {width <= 768 ? (
            <div className={styles.CentralBox}>
              <ContactBlock
                cta_link="https://www.cupraofficial.de/kontakt/probefahrt"
                cta_text="Probefahrt"
                eventName="internalLink"
                moduleComponent="Probefahrt"
                elementName="Cupra Links"
                cta_type="Primary CTA"
                cupraLinkBlock
                narrow
                smallText
              >
                <div className={styles.CupraLinkParagraph}>
                  <p>Zeit für ein rennsportlich</p>
                  <p>inspiriertes Fahrgefühl?</p>
                </div>
              </ContactBlock>
            </div>
          ) : (
            <>
              <ContactBlock
                cta_link="https://www.cupraofficial.de/kontakt/probefahrt"
                cta_text="Probefahrt"
                eventName="internalLink"
                moduleComponent="Probefahrt"
                elementName="Cupra Links"
                cta_type="Primary CTA"
                cupraLinkBlock
                narrow
                smallText
              >
                <div className={styles.CupraLinkParagraph}>
                  <p>Zeit für ein rennsportlich</p>
                  <p>inspiriertes Fahrgefühl?</p>
                </div>
              </ContactBlock>
              <div className={styles.Divider}></div>
            </>
          )}
          <ContactBlock
            cta_link="https://www.cupraofficial.de/kontakt/haendlersuche"
            cta_text="CUPRA Garagen"
            eventName="internalLink"
            moduleComponent="CUPRA Garagen"
            elementName="Cupra Links"
            cta_type="Primary CTA"
            cupraLinkBlock
            narrow
            smallText
          >
            <div className={styles.CupraLinkParagraph}>
              <p>CUPRA live sehen.</p>
              <p>Und spüren.</p>
            </div>
          </ContactBlock>
        </div>
      </div>
    </div>
  );
};

export default CupraLinks;

import React from "react";
import "./App.css";
import ImageHeader from "./components/ImageHeader";
import WelcomeText from "./components/WelcomeText";
import Request from "./components/Request";
import CustomerService from "./components/CustomerService";
import CupraLinks from "./components/CupraLinks";
import useWindowDimensions from "./components/useWindowDimensions";
import "./style/style.css";
import "iframe-resizer/js/iframeResizer.contentWindow";

function App() {
  const { width } = useWindowDimensions();
  return (
    <div className="App">
      <div id="HeaderBackground">
        <ImageHeader />
      </div>
      <div style={{ backgroundColor: "#1b1b1b" }}>
        <WelcomeText />
      </div>
      <div style={{ backgroundColor: "white" }}>
        <Request />
      </div>
      {width <= 768 ? (
      <div style={{ backgroundColor: "white" }}>
        <CustomerService />
      </div>):(
        <div style={{ backgroundColor: "#f8f8f8" }}>
        <CustomerService />
      </div>
      )}
      <div style={{ backgroundColor: "#1b1b1b" }}>
        <CupraLinks />
      </div>
    </div>
  );
}

export default App;

import React from "react";
import styles from "./Request.module.css";
import "../style/style.css";
import useWindowDimensions from "./useWindowDimensions";

import Button from "./comp/Button";
import Heading from "./comp/Heading";

const ContactBox = (props: {
  title: string;
  subtitle1: string;
  subtitle2?: string;
  phone?: string;
  cta_text: string;
  cta_link: string;
  eventName: string;
  moduleComponent: string;
  elementName: string;
  cta_type: string;
  email?: boolean;
}) => {
  const { width } = useWindowDimensions();
  let cta_link = "";
  if (props.email) {
    cta_link = "mailto:" + props.cta_link;
  } else {
    cta_link = props.cta_link;
  }
  return (
    <div className={`${styles.ContactBoxContainer}`}>
      <div className={styles.ContacBoxLeft}>
        <h1 className={styles.ContacBoxTitle}>{props.title}</h1>
        <div className={styles.SubTitles}>
          {width > 768 ? (
            <>
              <p className={styles.SingleSubtitle}>{props.subtitle1}</p>
              {props.subtitle2 && (
                <p className={styles.SingleSubtitle}>{props.subtitle2}</p>
              )}
            </>
          ) : (
            <p className={styles.SingleSubtitle}>
              {props.subtitle1} {props.subtitle2}
            </p>
          )}
        </div>
      </div>
      <div className={styles.ContacBoxRight}>
        <Button
          cta_link={cta_link}
          cta_text={props.cta_text}
          eventName={props.eventName}
          moduleComponent={props.moduleComponent}
          elementName={props.elementName}
          cta_type={props.cta_type}
        ></Button>
        {width > 768 &&
          (props.phone ? (
            <p style={{ fontSize: 16 }}>Per Telefon: {props.phone}</p>
          ) : (
            <p></p>
          ))}
        <br />
        {width <= 768 && props.phone && (
          <Button
            cta_link={props.phone}
            cta_text="Jetzt anrufen"
            eventName={props.eventName}
            moduleComponent={props.moduleComponent}
            elementName={props.elementName}
            cta_type={props.cta_type}
            transparent
          ></Button>
        )}
      </div>
    </div>
  );
};

const Request = () => {
  return (
    <div className={styles.RequestContainer + " Container"}>
      <div className={styles.HeaderContainer}>
        <h1 className={styles.Eyebrow}>Unsere Experten</h1>
        <Heading title="Für Spezielle Anfragen." />
      </div>
      <div className={styles.ContactBoxList}>
        <ContactBox
          title="Cupra Connect"
          subtitle1="Du hast Fragen. Zu CUPRA Connect."
          subtitle2="Unsere Experten sind für dich da."
          phone="00800 – 6000 40 20"
          cta_text="E-Mail senden"
          cta_link="cupradigitalsupport@cupraofficial.com"
          eventName="interaction"
          moduleComponent="CUPRA CONNECT"
          elementName="Spezielle Anfragen"
          cta_type="Email"
          email
        ></ContactBox>
        <ContactBox
          title="Volkswagen financial services"
          subtitle1="Du hast Fragen. Zu Leasing, Finanzierung oder Versicherungen."
          subtitle2="Volkswagen Financial Services hilft dir weiter."
          phone="+49 531 212-02"
          cta_text="Anfrage stellen"
          cta_link="https://www.vwfs.de/kontakt.html"
          eventName="externalLink"
          moduleComponent="VOLKSWAGEN FINANCIAL SERVICES"
          elementName="Spezielle Anfragen"
          cta_type="Primary CTA"
        ></ContactBox>
        <ContactBox
          title="Cupra for business"
          subtitle1="Du  bist selbstständig, Großkunde oder fährst CUPRA als"
          subtitle2="Firmenwagen? CUPRA Business kümmert sich um dein Anliegen."
          cta_text="Anfrage stellen"
          cta_link="https://www.cupraofficial.de/cupra-for-business/kontakt"
          eventName="internalLink"
          moduleComponent="CUPRA FOR BUSINESS"
          elementName="Spezielle Anfragen"
          cta_type="Primary CTA"
        ></ContactBox>
      </div>
    </div>
  );
};

export default Request;

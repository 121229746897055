import React from "react";
import styles from "./Heading.module.css";

const Heading = (props: {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  white?: boolean;
  bottom?: boolean;
}) => {
  return (

      <div
        className={`${styles.HeadingBlock} ${
          props.white ? styles.HeadingWhiteFont : ""
        } ${props.bottom ? styles.HeadingToParentsBottom : ""}`}
      >
        <h2 className={styles.HeadingBlockTitle}>{props.title}</h2>
        {props.subtitle && (
          <h2 className={styles.HeadingBlockTitle}>{props.subtitle}</h2>
        )}
        {props.children && (
          <p className={styles.HeadingBlockInfo}>{props.children}</p>
        )}
      </div>

  );
};

export default Heading;

import React from "react";
import styles from "./WelcomeText.module.css";

const WelcomeText = () => {
  return (
    <div className={`${styles.WelcomeContainer} Container`}>
      <div className={styles.WelcomeTextContainer}>
        <div className={styles.WelcomeText}>
          <div className={styles.WelcomeTextTop}>
            Wir sind für dich da. Schnell. <br/>Und unkompliziert.
            <br />
          </div>
          <div style={{lineHeight: "24px"}}>
          CUPRA. Ist für uns mehr als ein Fahrzeug. Es ist Leidenschaft. In
          jedem Detail. In jedem Service. Darum haben wir für alle Anliegen die
          richtige Ansprechperson sowie schnelle und professionelle Lösungen.
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeText;

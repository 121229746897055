import React from "react";
import styles from "./ImageHeader.module.css";
import "../style/style.css";
import Heading from "./comp/Heading";

const ImageHeader = () => {
  return (
    <div className={`${styles.HeaderDiv} Container`}>
      <Heading
        title="Dein Kontakt."
        subtitle="Zu Cupra."
        white
        bottom
      ></Heading>
    </div>
  );
};

export default ImageHeader;
